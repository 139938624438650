@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Belanosima:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700;800&display=swap');

.custom::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar {
    width: 0px;
}

.zIndexInfinite {
    z-index: 9999;
}

/* .react-flow {
    overflow: auto !important;
} */

.react-flow__renderer {
    position: fixed !important;
}