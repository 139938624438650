.edgebutton {
    width: 30px;
    height: 30px;
    background: #344767;
    border: 1px solid #647fad;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 0.5;
    justify-content: center;
    align-items: center;
    display: flex;
  }

.removebutton {
    width: 30px;
    height: 30px;
    background: #cc4767;
    border: 1px solid #ee4767;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 0.5;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }
  
  .edgebutton-foreignobject div {
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }
  